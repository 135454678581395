<template>
  <v-layout justify-center align-center>
    <v-flex lg8>
      <v-card v-if="authUser" class="pa-3">
        <v-card-title class="headline text-uppercase">
          <v-btn
            @click="
              () => {
                $router.push({ name: 'home' });
              }
            "
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          {{ $lang("Analytics") }}
        </v-card-title>
      </v-card>

      <!-- <StatsDeviceActivity /> -->
    </v-flex>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";
// import StatsDailyAttempts from "../modules/StatsDailyAttempts";
import { mapGetters } from "vuex";
export default {
  name: "graph",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {},
  methods: {
    handleSort(grouped) {
      // grouped.sort((a, b) => {
      //   return parseInt(a.key) < parseInt(b.key)
      //     ? -1
      //     : parseInt(b.key) < parseInt(a.key)
      //     ? 1
      //     : 0;
      // });
      // return grouped;
      return grouped.reverse();
    },
  },
  created() {},
};
</script>